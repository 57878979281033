import { Season, SeasonResponse } from '@/types/Season'
import { Week, WeeksArray } from '@/types/Week'
import axios from 'axios'
import { DateTime } from 'ts-luxon'
import { ApiResponse, apiUrl, handleApiResponse } from '../util'

export async function currentSeason(): Promise<SeasonResponse> {
	return handleApiResponse(
		axios({
			method: 'GET',
			url: apiUrl('/api/season/current'),
		}),
	).then((response: ApiResponse): SeasonResponse => {
		if (response.success) {
			return {
				success: true,
				season: {
					...response.response.data.data,
					weeks: Object.keys(
						response.response.data.data.weeks,
					).reduce((weeks, idx: string | number) => {
						const week = response.response.data.data.weeks[idx]
						weeks[idx] = {
							...week,
							open_at: DateTime.fromISO(week.open_at),
							start_at: DateTime.fromISO(week.start_at),
							end_at: DateTime.fromISO(week.end_at),
							isCurrent: idx === 'current' ? true : false,
						} as Week

						return weeks
					}, {} as WeeksArray) as WeeksArray,
				} as Season,
			}
		}

		return {
			success: false,
			message: response.error!.message,
		}
	})
}
