<template>
	<layout-main>
		<div class="py-8 text-center">
			<h2 class="text-6xl font-bold">Not found</h2>
			<p class="mt-4">
				Sorry, the page you are looking for does not exist.
			</p>

			<div class="mt-8">
				<router-link :to="{ name: 'home' }" class="button">
					Go to Home
				</router-link>
			</div>
		</div>
	</layout-main>
</template>
<script lang="ts" setup>
import { useLogger } from '@/modules/log'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

const log = useLogger()
const route = useRoute()

onMounted(() => {
	log.error('Page Not Found', {
		route: route.fullPath,
	})
})
</script>
