import { AccountDetailsResponse } from '@/types/AccountDetails'
import axios from 'axios'
import { ApiResponse, apiUrl, handleApiResponse } from '../util'

export async function accountDetails(): Promise<AccountDetailsResponse> {
	return handleApiResponse(
		axios({
			method: 'GET',
			url: apiUrl(`/api/account/account-details`),
		}),
	).then((apiResponse: ApiResponse): AccountDetailsResponse => {
		if (apiResponse.success) {
			return {
				success: true,
				account: apiResponse.response.data,
			} as AccountDetailsResponse
		}

		return {
			success: false,
			message: apiResponse.error!.message,
		}
	})
}
