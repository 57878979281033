<template>
	<div
		class="text-lg mt-4 p-4 mx-4 md:mx-0 rounded-lg bg-gray-100 grid gap-4 grid-cols-[5rem,1fr]"
	>
		<div class="text-blue-800 text-center py-2">
			<fa icon="fa-solid fa-info-circle" class="size-14" />
		</div>

		<div class="self-center">
			This season's points leaderboard is based on aggregate points earned
			across the

			<a
				href="https://set-builder.collecthth.com/hth-football-2k24"
				class="underline hover:no-underline underline-offset-2 text-teal-800"
				>Football 2024</a
			>

			season.
		</div>
	</div>

	<div
		class="grid grid-cols-[4fr,2fr,2fr,2fr] mt-6 mx-4 divide-y divide-y-gray-200"
	>
		<div class="grid grid-cols-subgrid col-span-4 text-base/[2.5rem]">
			<div class="text-left font-semibold px-2">Rank / User</div>

			<div
				class="text-right font-semibold px-2 cursor-pointer whitespace-nowrap"
				@click="sortBy('powerPlayRank')"
			>
				<span class="max-sm:hidden">Power Play</span> W/L

				<template v-if="sortKey === 'powerPlayRank'">
					<fa
						:icon="
							sortOrder === 'desc'
								? 'fa-solid fa-arrow-up'
								: 'fa-solid fa-arrow-down'
						"
					/>
				</template>
				<template v-else>
					<fa :icon="'fa-solid fa-arrows-up-down'" />
				</template>
			</div>

			<div
				class="text-right font-semibold cursor-pointer px-2"
				@click="sortBy('total_points')"
			>
				<span class="max-sm:hidden">Total</span> Points
				<template v-if="sortKey === 'total_points'">
					<fa
						:icon="
							sortOrder === 'desc'
								? 'fa-solid fa-arrow-up'
								: 'fa-solid fa-arrow-down'
						"
					/>
				</template>
				<template v-else>
					<fa :icon="'fa-solid fa-arrows-up-down'" />
				</template>
			</div>

			<div class="text-right font-semibold px-2">
				Entries <span class="max-sm:hidden">Earned</span>
			</div>
		</div>

		<template v-for="row in paginatedData">
			<div
				class="grid grid-cols-subgrid col-span-5 text-base/[2.5rem]"
				:class="{
					'bg-green-100':
						row.account_identifier === authStore.user?.username,
				}"
			>
				<div
					class="text-left font-normal py-2 pl-2 whitespace-nowrap truncate"
				>
					<div
						class="inline-block size-12 rounded-full text-sm/[3rem] text-center mr-2 font-bold"
						:class="[rankClasses(currentRank(row))]"
					>
						{{ currentRank(row) }}
					</div>
					{{ row.account_identifier }}
					<div
						v-if="
							row.account_identifier === authStore.user?.username
						"
						class="inline-block bg-green-600 text-white uppercase text-xs align-middle px-2 py-1 rounded-md ml-2"
					>
						You
					</div>
				</div>
				<div class="py-2 px-6 text-right whitespace-nowrap">
					{{ row.wins }} / {{ row.losses }}
				</div>
				<div class="text-right py-2">
					{{ row.total_points.toLocaleString() }}
				</div>
				<div class="text-right py-2 pr-2">
					{{ entriesEarned(Number(row.rank)).toLocaleString() }}
				</div>
			</div>
		</template>
	</div>
</template>

<script lang="ts" setup>
import { usePickSixStore } from '@/stores/PickSixStore'
import { Season, SeasonResponse } from '@/types/Season'
import { computed, onBeforeMount, ref } from 'vue'
import { useAuthStore } from '@/stores/AuthStore'

import { useLogger } from '@/modules/log'
import { useSeasonStore } from '@/stores/SeasonStore'

const authStore = useAuthStore()
const log = useLogger()

const pickSixStore = usePickSixStore()
const seasonStore = useSeasonStore()

const season = ref<Season | null>(null)
const leaderboard = ref([]) // Initialize with an empty array

const currentPage = ref(1)
const rowsPerPage = 250

const sortKey = ref<'powerPlayRank' | 'total_points'>('total_points')
const sortOrder = ref<'asc' | 'desc'>('asc') // Default to ascending for PowerPlayRank

function sortBy(key: 'powerPlayRank' | 'total_points') {
	if (sortKey.value === key) {
		sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc'
	} else {
		sortKey.value = key
		sortOrder.value = 'desc'
	}
	applySort()
}

function applySort() {
	leaderboard.value = [...leaderboard.value].sort((a, b) => {
		if (sortKey.value === 'powerPlayRank') {
			// Sorting by powerPlayRank, handle null or 0 as worst rank
			const rankA = a.powerPlayRank || Infinity // Null/0 becomes worst rank
			const rankB = b.powerPlayRank || Infinity
			return sortOrder.value === 'asc' ? rankA - rankB : rankB - rankA
		} else if (sortKey.value === 'total_points') {
			// Sorting by total_points, include all users
			const pointsA = a.total_points || 0
			const pointsB = b.total_points || 0
			return sortOrder.value === 'asc'
				? pointsA - pointsB
				: pointsB - pointsA
		}
		return 0 // Default case, should not be hit
	})
}

function currentRank(row: any): number {
	return sortKey.value === 'powerPlayRank'
		? row.powerPlayRank
			? row.powerPlayRank
			: 'N/A'
		: row.totalPointsRank || row.rank
}

const paginatedData = computed(() => {
	const start = (currentPage.value - 1) * rowsPerPage
	const end = start + rowsPerPage
	return leaderboard.value.slice(start, end)
})

function rankClasses(rank: number): string {
	if (rank == 1) {
		return 'bg-gradient-to-br from-[#e8e2b2] via-[#a8811f] to-[#e8e2b2] text-white shadow-lg'
	}

	if (rank == 2) {
		return 'bg-gradient-to-br from-zinc-800 via-zinc-900 to-zinc-800 text-white shadow-lg'
	}

	if (rank == 3) {
		return 'bg-gradient-to-br from-[#A97142] via-[#A94D00] to-[#A97142] text-white shadow-lg'
	}

	return 'bg-gray-200'
}

function entriesEarned(rank: number): number {
	const def = [
		[1, 5, 1000],
		[6, 10, 500],
		[11, 25, 350],
		[26, 50, 250],
		[51, 75, 200],
		[76, 100, 100],
		[101, 250, 50],
	]

	for (const v of def) {
		if (rank >= v[0] && rank <= v[1]) {
			return v[2]
		}
	}

	return 1
}

onBeforeMount(async () => {
	await authStore.getAccount()

	if (seasonStore.state.currentSeason) {
		season.value = seasonStore.state.currentSeason
	} else {
		await seasonStore.loadCurrentSeason()
		season.value = seasonStore.state.currentSeason
	}

	await combinedSeasonLeaderboard(season.value?.slug as string)
})

async function combinedSeasonLeaderboard(seasonSlug: string) {
	try {
		const [pointsResponse, powerPlayResponse] = await Promise.all([
			pickSixStore.pointsLeaderboard(seasonSlug),
			pickSixStore.powerPlayLeaderboard(seasonSlug),
		])

		if (pointsResponse.success && powerPlayResponse.success) {
			const pointsLeaderboard = pointsResponse.leaderboard!
			const powerPlayLeaderboard = powerPlayResponse.leaderboard!

			const powerPlayMap = new Map(
				powerPlayLeaderboard.map((entry) => [entry.username, entry]),
			)

			leaderboard.value = pointsLeaderboard.map((entry) => {
				const powerPlayEntry = powerPlayMap.get(
					entry.account_identifier,
				)

				return {
					...entry,
					powerPlayRank: powerPlayEntry?.rank || 0,
					wins: powerPlayEntry?.wins || 0,
					losses: powerPlayEntry?.losses || 0,
				}
			})

			sortBy('total_points')

			return
		}

		throw new Error(
			`Failed to load leaderboard data: ${
				!pointsResponse.success ? 'Points' : 'Power Play'
			}`,
		)
	} catch (err) {
		log.error('Combined Leaderboard: failed to load user data', {
			err: err.message,
			season: season.value,
			leaderboard: leaderboard.value,
		})
	}
}

// async function seasonLeaderboard(seasonSlug: string) {
// 	await pickSixStore
// 		.pointsLeaderboard(seasonSlug)
// 		.then((response: LeaderboardResponse) => {
// 			if (response.success) {
// 				leaderboard.value = response.leaderboard!
// 				return
// 			}
// 			throw new Error('Failed to load user entries')
// 		})
// 		.catch((err) => {
// 			log.error('ViewEntries: failed to load user data', {
// 				err: err.message,
// 				season: season.value,
// 				leaderboard: leaderboard.value,
// 			})
// 		})
// }

// async function seasonLeaderboardPowerPlay(seasonSlug: string) {
// 	await pickSixStore
// 		.powerPlayLeaderboard(seasonSlug)
// 		.then((response: PowerPlayLeaderboardResponse) => {
// 			if (response.success) {
// 				leaderboard.value = response.leaderboard!
// 				return
// 			}
// 			throw new Error('Failed to load leaderboard')
// 		})
// 		.catch((err) => {
// 			log.error('Season Leaderboard: failed to load user data', {
// 				err: err.message,
// 				season: season.value,
// 				leaderboard: leaderboard.value,
// 			})
// 		})
// }
</script>
