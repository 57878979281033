<template>
	<router-view
		id="app-main"
		class=""
		:key="route.fullPath.replace(/(#.*)$/, '')"
	/>
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router'
const route = useRoute()
</script>

<style>
@import '@/styles/app.css';
</style>
