import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faArrowDown,
	faArrowUp,
	faArrowsUpDown,
	faCalendar,
	faCaretDown,
	faCaretUp,
	faCheck,
	faCheckCircle,
	faChevronDown,
	faChevronUp,
	faCircleXmark,
	faEye,
	faHome,
	faHouse,
	faInfoCircle,
	faList,
	faMinus,
	faPlus,
	faRightFromBracket,
	faSearch,
	faSpinner,
	faStar,
	faTrash,
	faTrophy,
	faUser,
	faUserCircle,
	faUserGroupSimple,
	faUserLarge,
	faXmark,
} from '@awesome.me/kit-58ff991e8b/icons/classic/solid'

library.add(
	faArrowDown,
	faArrowUp,
	faArrowsUpDown,
	faCalendar,
	faCaretDown,
	faCaretUp,
	faCheck,
	faCheckCircle,
	faChevronDown,
	faChevronUp,
	faCircleXmark,
	faEye,
	faHome,
	faHouse,
	faInfoCircle,
	faList,
	faMinus,
	faPlus,
	faRightFromBracket,
	faSearch,
	faSpinner,
	faStar,
	faTrash,
	faTrophy,
	faUser,
	faUserCircle,
	faUserGroupSimple,
	faUserLarge,
	faXmark,
)
