<template>
	<div class="p-4">
		<div
			v-for="(hero, idx) in heroes"
			:key="idx"
			class="flex items-center p-4 mb-4 bg-white rounded shadow"
		>
			<img
				:src="hero.image_url"
				alt="hero image"
				class="w-16 h-16 mr-4 rounded-full"
			/>
			<div class="flex-1">
				<div class="flex">
					<h3 class="text-lg font-bold">{{ hero.hero_name }}</h3>

					<div
						v-if="hero.game_status === 'in-progress'"
						class="flex items-center ml-2 text-red-500"
					>
						<span
							class="w-2 h-2 bg-red-500 rounded-full animate-ping absolute inline-block"
						></span>
						<span
							class="w-2 h-2 bg-red-500 rounded-full relative"
						></span>
						<span class="ml-2 font-bold text-sm">LIVE</span>
					</div>
				</div>
				<div class="mt-2">
					<div class="flex items-center">
						<span class="mr-2 text-sm">Performance:</span>
						<div class="w-full bg-gray-200 rounded h-2 relative">
							<div
								:class="{
									'bg-green-500': isWinning(hero),
									'bg-red-500': !isWinning(hero),
								}"
								:style="{
									width: performanceWidth(
										hero.actual_points,
										hero.projected_points,
									),
								}"
								class="h-2 rounded"
							></div>
							<span
								v-if="
									hero.actual_points > hero.projected_points
								"
								class="absolute right-0 text-xs text-white bg-gray-800 rounded px-1"
							>
								{{ hero.actual_points }}
							</span>
						</div>
					</div>
				</div>
				<div class="mt-2 flex items-center">
					<span class="mr-4 text-sm"
						>Projected: {{ hero.projected_points }}</span
					>
					<span class="mr-4 text-sm"
						>Actual: {{ hero.actual_points ?? 0 }}</span
					>
					<span
						:class="{
							'text-green-500': isWinning(hero),
							'text-red-500': !isWinning(hero),
						}"
						class="text-sm font-bold"
					>
						{{ isWinning(hero) ? 'Winning' : 'Losing' }}
					</span>
				</div>
			</div>
			<button
				class="ml-4 px-4 py-2 text-white bg-teal-800 rounded hover:bg-teal-900"
				@click="openModal(idx)"
			>
				View Stats
			</button>
		</div>
	</div>
	<div>
		<PlayerModal
			v-if="isModalOpen"
			:hero="currentHero"
			:heroList="heroes"
			:createModal="false"
			:currentIndex="currentIndex"
			@close="closeModal"
			@change="changePlayer"
		/>
	</div>
</template>

<script lang="ts" setup>
import { ref, computed, onBeforeMount } from 'vue'
import { usePickSixStore } from '@/stores/PickSixStore'
import { Hero, WeekHero } from '@/types/Hero'
import { Season } from '@/types/Season'
import PlayerModal from '@/components/Heroes/PlayerStatsModal.vue'
import { Entry } from '@/types/Entry'
import Pusher from 'pusher-js'

const PUSHER_KEY =
	import.meta.env.VITE_PUSHER_KEY ??
	(() => {
		throw new Error('VITE_PUSHER_KEY environment variable is required')
	})()
const PUSHER_CLUSTER =
	import.meta.env.VITE_PUSHER_CLUSTER ??
	(() => {
		throw new Error('VITE_PUSHER_CLUSTER environment variable is required')
	})()

// Pusher setup
const pusher = new Pusher(PUSHER_KEY, {
	cluster: PUSHER_CLUSTER,
	forceTLS: true,
})

const channel = pusher.subscribe('heroes')

const props = withDefaults(
	defineProps<{
		entries: Entry[]
		season: Season
		weekNumber: string
	}>(),
	{},
)

const isModalOpen = ref(false)
const currentIndex = ref(0)

// Reactive source for heroes
const heroes = ref<Hero[]>([])

onBeforeMount(() => {
	const pickedHeroIds = new Set(
		props.entries.flatMap((entry) => entry.heroes.map((h) => h.id)),
	)

	const week = Object.values(props.season.weeks).find(
		(week) => week.id === props.weekNumber,
	)

	if (week) {
		heroes.value = week.heroes.filter((h: Hero) => pickedHeroIds.has(h.id))
	}
})

channel.bind('pusher:subscription_succeeded', () => {
	console.log('Subscription to heroes channel succeeded')
})

// Update hero points via Pusher
channel.bind('HeroScoreUpdated', (data: { hero: any }) => {
	updateHeroPoints(data)
})

function openModal(idx: number) {
	const firstHero = heroes.value[idx]
	if (firstHero) {
		currentIndex.value = idx
	}
	isModalOpen.value = true
	document.body.style.overflow = 'hidden'
}

function closeModal() {
	isModalOpen.value = false
	document.body.style.overflow = ''
}

function changePlayer(index: number) {
	if (heroes.value[index]) {
		currentIndex.value = index
	}
}

const currentHero = computed(() => {
	return heroes.value[currentIndex.value] || null
})

const isWinning = (hero: WeekHero) => {
	return (
		hero.projected_points > 0 && hero.actual_points > hero.projected_points
	)
}

const performanceWidth = (actual: number, projected: number) => {
	const percentage =
		projected > 0 ? Math.min((actual / projected) * 100, 100) : 0
	return `${percentage}%`
}

const updateHeroPoints = (updatedHero: any) => {
	const heroIndex = heroes.value.findIndex(
		(hero) => hero.id === updatedHero.hero_id,
	)

	if (heroIndex !== -1) {
		const updatedHeroData = {
			...heroes.value[heroIndex],
			actual_points: updatedHero.actual_points,
		}

		heroes.value.splice(heroIndex, 1, updatedHeroData)

		console.log(
			`Updated actual_points for hero: ${updatedHeroData.hero_name}`,
		)
	}
}
</script>
