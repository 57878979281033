import { PowerPlayLeaderboardResponse } from '@/types/Leaderboard'
import axios from 'axios'
import { ApiResponse, apiUrl, handleApiResponse } from '../util'

export async function powerPlayLeaderboard(
	seasonSlug: string,
): Promise<PowerPlayLeaderboardResponse> {
	return handleApiResponse(
		axios({
			method: 'GET',
			url: apiUrl(`/api/season/${seasonSlug}/leaderboard`),
		}),
	).then((apiResponse: ApiResponse): PowerPlayLeaderboardResponse => {
		if (apiResponse.success) {
			return {
				success: true,
				leaderboard: apiResponse.response.data.data,
			}
		}

		return {
			success: false,
			message: apiResponse.error!.message,
		}
	})
}
