import * as api from '@/api'
import { Season } from '@/types/Season'
import { Week, WeeksArray } from '@/types/Week'

import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'

export const useSeasonStore = defineStore('season', () => {
	function init() {
		return {
			state,
			loadCurrentSeason,
			currentWeek,
			weeksById,
		}
	}

	async function loadCurrentSeason() {
		try {
			const response = await api.pick6.currentSeason()
			if (response.success && response.season) {
				state.value.currentSeason = response.season
				return response.season
			}
			throw new Error('Failed to load season')
		} catch (err) {
			console.error('Failed to load current season', err)
			return null
		}
	}

	const currentWeek: ComputedRef<Week | null> = computed(() => {
		const season = state.value.currentSeason
		if (!season?.weeks) {
			return null
		}

		const weeks = season.weeks as WeeksArray

		return Object.values(weeks).find((week: Week) => week.isCurrent) || null
	})

	const weeksById: ComputedRef<WeeksArray> = computed(() => {
		if (!state.value.currentSeason) {
			return {} as WeeksArray
		}

		return Object.values(
			state.value.currentSeason.weeks as WeeksArray,
		).reduce((wks, wk) => {
			wks[wk.id] = wk
			return wks
		}, {} as WeeksArray)
	})

	const state = ref<{
		currentSeason: Season | null
	}>({
		currentSeason: null,
	})

	return init()
})
