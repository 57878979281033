import { DataResponse } from '@/types/App'
import axios from 'axios'
import { ApiResponse, apiUrl, handleApiResponse } from '../util'

export async function seasonWeeksEntries(
	season: string,
): Promise<DataResponse> {
	return handleApiResponse(
		axios({
			method: 'GET',
			url: apiUrl(`/api/account/season/${season}/weeks`),
		}),
	).then((apiResponse: ApiResponse): DataResponse => {
		if (apiResponse.success) {
			return {
				success: true,
				data: apiResponse.response.data.data,
			}
		}

		return {
			success: false,
			message: apiResponse.error?.message ?? 'An unknown error occurred',
		}
	})
}
