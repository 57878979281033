import * as api from '@/api'
import { AccountDetailsResponse } from '@/types/AccountDetails'
import { DataResponse } from '@/types/App'
import {
	DeleteEntryResponse,
	ListEntryResponse,
	SaveEntryResponse,
} from '@/types/Entry'
import {
	LeaderboardResponse,
	PowerPlayLeaderboardResponse,
} from '@/types/Leaderboard'
import { SeasonResponse } from '@/types/Season'

import { defineStore } from 'pinia'

export const usePickSixStore = defineStore('pickSix', () => {
	async function currentSeason(): Promise<SeasonResponse> {
		return api.pick6.currentSeason()
	}

	async function pointsLeaderboard(
		seasonSlug: string,
	): Promise<LeaderboardResponse> {
		return api.pick6.pointsLeaderboard(seasonSlug)
	}

	async function powerPlayLeaderboard(
		seasonSlug: string,
	): Promise<PowerPlayLeaderboardResponse> {
		return api.pick6.powerPlayLeaderboard(seasonSlug)
	}

	async function accountDetails(): Promise<AccountDetailsResponse> {
		return api.account.accountDetails()
	}

	async function createEntry(
		seasonSlug: string,
		weekId: string,
		heroIds: string[],
		entryCount: number,
		powerPlay: boolean,
	): Promise<SaveEntryResponse> {
		return api.account.createEntry(
			seasonSlug,
			weekId,
			entryCount,
			heroIds,
			powerPlay,
		)
	}

	async function deleteEntry(entryId: string): Promise<DeleteEntryResponse> {
		return api.account.deleteEntry(entryId)
	}

	async function loadEntries(
		seasonSlug: string,
		weekId: string | null = null,
	): Promise<ListEntryResponse> {
		return api.account.entries(seasonSlug, weekId)
	}

	async function seasonWeeksEntries(
		seasonSlug: string,
	): Promise<DataResponse> {
		return api.account.seasonWeeksEntries(seasonSlug)
	}

	function init() {
		return {
			// actions
			createEntry,
			deleteEntry,
			loadEntries,
			currentSeason,
			pointsLeaderboard,
			accountDetails,
			powerPlayLeaderboard,
			seasonWeeksEntries,
		}
	}

	return init()
})
